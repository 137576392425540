import React from 'react'

import { StaticQuery, graphql } from 'gatsby'

import Layout from '../components/Layout'

import {
  Container,
  Section,
  Footer,
  NetlifyForm,
  Hero,
  Columns,
  Column,
  Button,
} from '../webhart-base'

import { css } from '@emotion/core'
import { colors, fonts, gradients } from '../webhart-base/utils/style'

export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Hero>
          <Container
            css={css`
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <h1>{data.site.siteMetadata.title}</h1>
          </Container>
        </Hero>
        <Section>
          <Container>{data.site.siteMetadata.title}</Container>
        </Section>
        <Section>
          <Container>
            <Columns>
              <Column>
                <h1>title</h1>
                <h2>title</h2>
                <h3>title</h3>
                <h4>title</h4>
                <h5>title</h5>
                <h6>title</h6>
              </Column>
              <Column>
                <p>
                  paragraph Nostrud duis aliqua veniam sint elit et eiusmod
                  esse. Amet velit nulla qui sint veniam mollit est laboris
                  commodo. Consectetur sint excepteur pariatur est nostrud
                  voluptate sit. Ea amet laborum consectetur nostrud.
                </p>
                <p
                  css={css`
                    font-weight: 200;
                  `}
                >
                  paragraph Nostrud duis aliqua veniam sint elit et eiusmod
                  esse. Amet velit nulla qui sint veniam mollit est laboris
                  commodo. Consectetur sint excepteur pariatur est nostrud
                  voluptate sit. Ea amet laborum consectetur nostrud.
                </p>
              </Column>
              <Column>
                <p>
                  <span>span</span>
                </p>
                <p>
                  <strong>strong</strong>
                </p>
                <p>
                  <i>italic</i>
                </p>
                <p>
                  <code>code</code>
                </p>
                <p>
                  <Button>Button</Button>
                </p>
              </Column>
              <Column>
                <p>
                  colors:
                  <br />
                  {Object.keys(colors).map(e => (
                    <>
                      <span
                        key={e}
                        css={css`
                          color: ${colors[e]};
                          background: ${colors[e]};
                          padding-left: 50px;
                          margin-right: 10px;
                        `}
                      />
                      <span
                        css={css`
                          font-family: ${fonts.mono};
                        `}
                      >
                        {colors[e]} {e}
                      </span>
                      <br />
                    </>
                  ))}
                </p>
                <p>
                  gradients
                  <br />
                  {Object.keys(gradients).map(e => (
                    <>
                      <span
                        key={e}
                        css={css`
                          background: ${gradients[e]};
                          width: 50px;
                          height: 50px;
                          display: inline-block;
                          margin-right: 10px;
                        `}
                      />
                      <span
                        css={css`
                          font-family: ${fonts.mono};
                        `}
                      >
                        {gradients[e]} {e}
                      </span>
                      <br />
                    </>
                  ))}
                </p>
              </Column>
            </Columns>
          </Container>
        </Section>
        <Footer>
          <Container>
            <NetlifyForm>
              <label>
                <span>name</span>
                <input required type="text" name="name" />
              </label>
              <label>
                <span>last name</span>
                <input required type="text" name="last name" />
              </label>
              <label>
                <span>email</span>
                <input required type="text" name="email" />
              </label>
            </NetlifyForm>
          </Container>
        </Footer>
      </Layout>
    )}
  />
)
